import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/jxie/projects/lyra/packages/web/src/components/content/ContentPage.tsx";
export const activityKeys = ['textStepperWhatElse'];
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Grid = makeShortcode("Grid");
const TextStepper = makeShortcode("TextStepper");
const Img = makeShortcode("Img");
const GoTo = makeShortcode("GoTo");
const layoutProps = {
  activityKeys,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "what-else-could-my-teenager-be-doing"
    }}>{`What else could my teenager be doing?`}</h1>
    <p>{`Gradually increasing your teenager’s responsibilities and independence over time will help them to mature. Encourage your teenager to try a variety of activities and interests to help them find out what they are interested in and what they are good at. This will help to boost their self-esteem and confidence. Ideas for activities include:`}</p>
    <Grid container spacing={2} mdxType="Grid">
  <Grid item mdxType="Grid">
    <TextStepper id="textStepperWhatElse" sequenceSpeed={1200} fadeSpeed={1200} list={[{
          text: 'Gymnastics'
        }, {
          text: 'Dance'
        }, {
          text: 'Singing'
        }, {
          text: 'Photography'
        }, {
          text: 'Martial arts'
        }, {
          text: 'Drama'
        }, {
          text: 'Athletics'
        }, {
          text: 'Cadets'
        }, {
          text: 'Scouts'
        }, {
          text: 'Music'
        }, {
          text: 'Paid and unpaid jobs'
        }, {
          text: 'Guides'
        }, {
          text: 'Art and craft'
        }, {
          text: 'Language classes'
        }, {
          text: 'Debating'
        }, {
          text: 'Soccer'
        }, {
          text: 'Netball'
        }, {
          text: 'Cricket'
        }, {
          text: 'Swimming'
        }, {
          text: 'Football'
        }, {
          text: 'Basketball'
        }, {
          text: 'Hockey'
        }, {
          text: 'Tennis'
        }, {
          text: 'Volleyball'
        }, {
          text: 'Any other sport you can think of. The list is endless!',
          long: true
        }]} mdxType="TextStepper" />
  </Grid>
  <Grid container justify="center" spacing={2} mdxType="Grid">
    <Grid item xs={8} sm={4} mdxType="Grid">
      <Img src="/images/m4/10-01.svg" alt="dance" mdxType="Img" />
    </Grid>
    <Grid item xs={8} sm={4} mdxType="Grid">
      <Img src="/images/m4/10-02.svg" alt="music" mdxType="Img" />
    </Grid>
    <Grid item xs={12} mdxType="Grid">
      <div>
        <h5>So your teenager is already involved in extra-curricular activities?</h5>
        <ul>
          <li>Show an interest in what they are doing.</li>
          <li>Check to see if they still enjoy it.</li>
          <li>Ask if they want to try something new (be curious but not pushy).</li>
        </ul>
        <p>
          For more ideas on how to talk with your teenager, see the module{' '}
          <GoTo to="/dashboard" mdxType="GoTo"><i>Connect</i></GoTo>.
        </p>
      </div>
    </Grid>
    <Grid item xs={8} sm={4} mdxType="Grid">
      <Img src="/images/m4/10-03.svg" alt="soccer" mdxType="Img" />
    </Grid>
    <Grid item xs={8} sm={4} mdxType="Grid">
      <Img src="/images/m4/10-04.svg" alt="photography" mdxType="Img" />
    </Grid>
  </Grid>
    </Grid>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      